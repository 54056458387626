import React, { useState, useEffect } from "react";
import YtbCardItem from './YtbCardItem';
import './YtbCard.css';

function YtbCard() {

    const [video, setVideo] = useState(null);
    useEffect(() => {
        fetch("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=6&playlistId=UULF_iO1_x6v386dn1hPttreLA&key=AIzaSyC1wynmDXegMDYdCeJ59IMyh0sZXLj9r24", {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                setVideo(data.items)
            })
            .catch((error) => console.log(error));
    }, []);

    return (
        <div className='cards-container'>
            <ul className='card'>
                {
                    video && video.map(video => {
                        return (
                            <YtbCardItem
                                src={video.snippet.thumbnails.medium.url}
                                text={video.snippet.title}
                                path={'https://www.youtube.com/watch?v='+video.contentDetails.videoId}
                                time={video.contentDetails.videoPublishedAt}
                            />
                        )

                    })
                }
            </ul>
        </div>


    );
}

export default YtbCard;