import React, { useState, useEffect } from "react";
import ReelCardItem from './ReelCardItem';
import './ReelCard.css';

function ReelCard() {
    return (
        <ul className='card'>
            <ReelCardItem
                src={"../photo/thumbnail1.gif"}
                path={"https://www.instagram.com/reel/Cvs1_SQLAA6/"}
            />
            <ReelCardItem
                src={"../photo/thumbnail2.gif"}
                path={"https://www.instagram.com/reel/CtLTRGNsHTa/"}
            />
            <ReelCardItem
                src={"../photo/thumbnail3.gif"}
                path={"https://www.instagram.com/reel/CsxWhiFOHfI/"}
            />
            <ReelCardItem
                src={"../photo/thumbnail4.gif"}
                path={"https://www.instagram.com/reel/CsGJpERNMuh/"}
            />
        </ul>
    );
}

export default ReelCard;