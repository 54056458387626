import React from 'react'
import '../../App.css'
import './About.css';

function About() {
  return (
    <>
      <div className='about-container'>
        <h4>coming soon</h4>
      </div>
    </>
  )
}

export default About
