import moment from 'moment';
import React from 'react';

function YtbCardItem(props) {
    return (

        <>
            <li className='card-list'>
                <a href={props.path} className='card-link' target="_blank" rel="noopener noreferrer">
                    <img
                        className='thumbnail'
                        alt='thumbnail'
                        src={props.src}
                    />
                    <div className='details'>
                        <p className='title'>{props.text}</p>
                        <p className='time'>{moment.utc(props.time).local().startOf('seconds').fromNow()}</p>
                    </div>
                </a>
            </li>
        </>
    );
}

export default YtbCardItem;