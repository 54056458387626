import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const scrollToDev = () => {
        setTimeout(function () {
            document.querySelector(".tech-container").scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 200)
        setClick(false);
    }
    const scrollToContent = () => {
        setTimeout(function () {
            document.querySelector(".content-container").scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 200)
        setClick(false);
    }

    const scrollToTop = () => {
        setTimeout(function () {
            document.querySelector(".home-container").scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 200)
        setClick(false);
    }

    const showButton = () => {
        if (window.innerWidth <= 1024) {
            setButton(false);
        } else {
            setButton(true);
        }
    }

    useEffect(() => {
        showButton()
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
                <Link to="/" className='navbar-logo' onClick={scrollToTop}>
                    @BLABLABLABLAIR
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fa-sharp fa-solid fa-xmark' : 'fa-solid fa-bars'}></i>
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to="/" className='nav-links' onClick={scrollToDev}>
                            tech
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to="/" className='nav-links' onClick={scrollToContent}>
                            content
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to="/About" className='nav-links' onClick={closeMobileMenu}>
                            about
                        </Link>
                    </li>
                    {/* <li className='nav-item nav-item-dropdown'>
                        <Link to="/About" className='nav-links' onClick={closeMobileMenu}>
                            other
                        </Link>
                        <ul class="dropdown-menu">
                            <li className='dropdown-item'>
                                <Link to="/Pottery" className='nav-links-dropdown' onClick={closeMobileMenu}>
                                    pottery
                                </Link>
                            </li>
                            <li className='dropdown-item'>
                                <Link to="/Pottery" className='nav-links-dropdown' onClick={closeMobileMenu}>
                                    about
                                </Link>
                            </li>
                        </ul>
                    </li> */}
                    <li className='nav-item'>
                        <Link to="/Contact" className='nav-links' onClick={closeMobileMenu}>
                            contact
                        </Link>
                    </li>
                </ul>
                <ul className={click ? 'nav-social active' : 'nav-social'}>
                    <li className='nav-item'>
                        <a href="https://www.linkedin.com/in/blairniu/" className='nav-links-social' target="_blank" rel="noopener noreferrer">
                            <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href="https://github.com/shuningn" className='nav-links-social' target="_blank" rel="noopener noreferrer">
                            <i class="fa-brands fa-github"></i>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href="https://www.youtube.com/@blablablablair" className='nav-links-social' target="_blank" rel="noopener noreferrer">
                            <i class="fa-brands fa-youtube"></i>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href="https://www.instagram.com/wifeoftraderjoes/" className='nav-links-social' target="_blank" rel="noopener noreferrer">
                            <i class="fa-brands fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Navbar
