import React from 'react'
import '../../App.css'
import './Content.css';
import YtbCard from '../YtbCard';
import ReelCard from '../ReelCard';

function Content() {

  const onclick = () => {
    window.open('https://www.instagram.com/wifeoftraderjoes/reels');
  }


  return (
    <>
      <div className='content-container'>
        <div className='title-container'>
          <h2>content creator</h2>
          <h3>I like making videos to show my future grandchildren.</h3>
        </div>
        <div className='youtube'>
          <a href="https://www.youtube.com/@blablablablair" className='social-link' target="_blank" rel="noopener noreferrer">
            <h4>youtube @blablablablair</h4>
            <i class="fa-solid fa-link"></i>
          </a>
          <YtbCard />
        </div>
        <div className='ig-t'>
          <a href="https://www.instagram.com/wifeoftraderjoes/" className='social-link' target="_blank" rel="noopener noreferrer">
            <h4>instagram @wifeoftraderjoes</h4>
            <i class="fa-solid fa-link"></i>
          </a>
          <h4>tiktok @wifeoftraderjoes</h4>
          <ReelCard />
          <div class="flex-parent jc-center">
            <button
              className={'viewmore-button'}
              onClick={onclick}
            >view more on instagram</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Content