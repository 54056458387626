import React from 'react';
import ReactPlayer from 'react-player'

function JobCard(props) {
    return (
        <>
            <div className='job-container'>
                <div className='job-card'>
                    <div className='job-time'>
                        <h5>{props.start}</h5>
                        <h5>|</h5>
                        <h5>{props.end}</h5>
                    </div>
                    <a href="https://about.meta.com/" target="_blank" rel="noopener noreferrer" className='job-detail'>
                        <h4 className='job-title'>{props.title}</h4>
                        <h5>{props.location}</h5>
                        <p>{props.detail}</p>
                        <ul className='technology'>
                            {
                                props.technology && props.technology.map(tech => {
                                    return (
                                        <li className='tech-button-list'>
                                            <button
                                                className={'tech-button'}
                                                onClick={console.log('.')}
                                                type={''}>
                                                {tech}</button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </a>
                </div>
                <div className='job-demo'>
                    <div className='demo-container'>
                        <img src={props.demo} alt="demo"></img>
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobCard;