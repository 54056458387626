import '../../App.css'
import './Contact.css';
import { useState, useRef, React } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {
  const form = useRef();
  const [errorString, setErrorString] = useState("");
  const [error, setError] = useState(false);
  const [toSend, setToSend] = useState({
    name: '',
    email: '',
    message: '',
  });
  const onSubmit = (e) => {
    e.preventDefault();
    if (form.current.email.value === "" || form.current.name.value === "" || 
      form.current.message.value === ""){
      setError(true);
      setErrorString("Some fields in the forms are empty.");
    } else {
    emailjs.sendForm('service_h3kl0k7', 'template_2p2itjk', form.current, 'VymII1XHSPYONRJ0V')
      .then((result) => {
        setErrorString("Submitted!");
        setError(true);
        setToSend({ ...toSend, ['message']: '' });
      }, (error) => {
        setError(true);
        setErrorString("for some reason, submission unsuccessful. you can also reach me on linkedin :)");
      });
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value })
  }

  return (
    <>
      <div className='contact-container'>
        <div className='contact-form'>
          <div className='form-container'>
            <h2>CONTACT ME</h2>
            <p>want to work together? want to chat? found a bug on this?</p>
            <form ref={form} onSubmit={onSubmit}>
              <label>your name</label>
              <input name='name' value={toSend.name} type="text" onChange={handleChange} />
              <label>email</label>
              <input name='email' value={toSend.email} type="text" onChange={handleChange} />
              <label>message</label>
              <textarea name='message' value={toSend.message} className='input-message' type="text" onChange={handleChange} /><br />
              <button
                className='submit'
                onClick={onclick}>submit</button>
              <label class={error? 'submitted' : 'submit-hidden'}>{errorString}</label>
            </form>
          </div>
        </div>
        <div className='form-photo'>
          <img src='../../photo/contact.png' alt='contact-me'></img>
        </div>
      </div>
    </>
  )
}

export default Contact
