import React from 'react';
import ReactPlayer from 'react-player'

function ReelCardItem(props) {
    return (

        <>
            <li className='igcard-list'>
                <a href={props.path} className='igcard-link' target="_blank" rel="noopener noreferrer">
                    {/* <ReactPlayer
                        className='react-player fixed-bottom'
                        url={props.src}
                        width='100%'
                        height='100%'
                        muted={true}
                        playing={true}
                        loop={true}
                        controls={false}
                    /> */}
                    <img src={props.src} alt="demo"></img>
                </a>
            </li>
        </>
    );
}

export default ReelCardItem;