import React from 'react';
import '../../App.css'
import './Home.css';
import Tech from './Tech';
import Content from './Content';
import ReactPlayer from 'react-player'
import Footer from '../Footer';

function Home() {
    return (
        <>
            <div className='home-container'>
                <div className='header-container'>
                    <div className='video-container'>
                        <a href='https://www.instagram.com/p/Cmvg2Wjo-1O/' target="_blank" rel="noopener noreferrer">
                            <img src='/photo/pottery_cropped.gif' alt="demo"></img>
                        </a>
                    </div>
                    <div className='home-main'>
                        <h1 className='home-title'>hi i'm blair</h1>
                        <h3 className='home-subtitle'>9-5 software engineer</h3>
                        <h3 className='home-subtitle'>24/7 pottery artist</h3>
                        <h3 className='home-subtitle'>365 pre-fame content creator</h3>
                    </div>
                </div>
                <hr class="separator" />
                <Tech />
                <hr class="separator" />
                <Content />
                <hr class="separator" />
                <Footer />
            </div>
        </>
    )
}

export default Home;