import React from 'react'
import '../../App.css'
import './Tech.css';
import JobCard from '../JobCard';

function Tech() {
  return (
    <>
      <div className='tech-container'>
        <div className='title-container'>
          <h2>software engineer</h2>
          <h3>After graduating from University of Southern California, I worked at Meta as an iOS engineer. My day to day work involves Objective C, graphql and C++. Here are some of the stuff I worked on.</h3>
        </div>
        <div className='experience'>
        <h4>experience</h4>
          <a href="https://www.linkedin.com/in/blairniu/" className='experience-linkedin' target="_blank" rel="noopener noreferrer">
            <p>did you know? each day without that 500+ linkedin connections, a little flower in my soul withers.</p>
            <i class="fa-brands fa-linkedin-in"></i>
          </a>
          <JobCard
            start={"feb 2021"}
            end={"jul 2023"}
            title={"software/ios engineer, meta"}
            location={"los angeles, ca"}
            demo={"/photo/demo_m.gif"}
            detail={"During my time at Meta, I worked as an software/iOS enginner on Meta Business Suite. I implemented and maintained MVP features on reels creation and management on Meta Business Suite (MBS) iOS, allowing users to view, manage, and schedule facebook and instagram reels."}
            technology={['objc', "graphql", "c++"]}
          />
        </div>
      </div>
    </>
  )
}

export default Tech