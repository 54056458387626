import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <>
      <div className='footer-container'>
          <div className='footer-gif'>
          </div>
          <p>designed and built by blair</p>
      </div>
    </>
  )
}

export default Footer
